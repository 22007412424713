import React from 'react';

const WhatsAppIcon = () => {
  const phoneNumber = '1234567890'; // Reemplaza con tu número de teléfono

  return (
    <a
      href={`https://wa.me/${phoneNumber}`}
      target="_blank"
      rel="noopener noreferrer"
      style={{ position: 'fixed', bottom: '20px', right: '20px' }}
      className='whatsapp'
    >
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
        alt="WhatsApp"
      />
    </a>
  );
};

export default WhatsAppIcon;
