import React from 'react';
import '../App.css';
import { NavLink } from 'react-router-dom'; // Usamos NavLink para manejar la clase activa


const navbar = () => {
  return (
   
     <nav className='navbar'>
      <div className="logo-container">
        <img src="logoSC.png" alt="Logo" className="logo" /> {/* Agrega el logo aquí */}
      </div>
     <ul>
       <li>
         <NavLink to="/" className={({ isActive }) => (isActive ? 'active' : '')}>
           Home
         </NavLink>
       </li>
       <li>
         <NavLink to="/equipo" className={({ isActive }) => (isActive ? 'active' : '')}>
           Equipo
         </NavLink>
       </li>
       <li>
         <NavLink to="/servicios" className={({ isActive }) => (isActive ? 'active' : '')}>
           Servicios
         </NavLink>
       </li>
     </ul>
   </nav>
  );
};

export default navbar;
