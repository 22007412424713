import React from 'react';
import WhatsAppIcon from '../components/whatsappIcon';


const servicios = () => {

  return (
    <div className='servicios'>
      <h1>Conoce todo <br></br> nuestro portafolio</h1>
      <div className='servicios1a1'>
        <div className="ovalPurple">
          <div className="circleGrey"></div>
        </div>
        <h2>Gestión de redes sociales</h2>
      </div>
      <ul>
        <li>Estrategia de comunicación.</li>
        <li>Desarrollo de estrategia.</li>
        <li>Creación y diseño de contenido *(La producción de videos y fotografías depende de la elección del
          plan contratado).</li>
        <li>Calendarios de contenidos con planificación mensual de publicaciones que incluye fechas y horas
          óptimas de publicación.</li>
        <li>Gestión de múltiples cuentas en diversas redes sociales como Facebook, Instagram y TikTok.</li>
        <li>Monitoreo y análisis del rendimiento de las publicaciones y anuncios.</li>
        <li>Análisis de resultados detallados con métricas clave (alcance, impresiones, engagment, conversiones).</li>
      </ul>
      <div className='btnGris'>
        <a href="https://wa.me/3206192322" target="_blank" rel="noopener noreferrer">¡Pregunta por nuestros planes!</a>
      </div>
      <div className='servicios1a1'>
        <div className="ovalPurple">
          <div className="circleGrey"></div>
        </div>
        <h2>Creación de marca</h2>
      </div>
      <ul>
        <li>Briefing y consulta inicial.</li>
        <li>Análisis y tendencias de diseño para asegurar que el logotipo sea relevante y moderno.</li>
        <li>Desarrollo de bocetos y propuetas de diseño que exploren diferentes ideas y enfoques visuales.</li>
        <li>Creación de versiones digitales de los mejores bocetos utilizando software de diseño gráfico 
          (como Adobe Ilustrator).</li>
        <li>Archivos finales en varios formatos digitales (como .AI, .EPS, .PDF, .PNG, .JPEG) para su uso en
          diferentes aplicaciones.</li>
        <li>Guía de uso del manual de identidad visual *(Dependiendo del plan contratado).</li>
      </ul>
      <div className='btnGris'>
        <a href="https://wa.me/3206192322" target="_blank" rel="noopener noreferrer">¡Pregunta por nuestros planes!</a>
      </div>
      <div className='servicios1a1'>
        <div className="ovalPurple">
          <div className="circleGrey"></div>
        </div>
        <h2>Pauta digital</h2>
      </div>
      <ul>
        <li>Desarrollo de estrategia publicitaria.</li>
        <li>Definición de objetivos.</li>
        <li>Indentificación de audiencia objetivo.</li>
        <li>Creación de anuncios visualmente atractivos, incluyendo imágenes y gráficos personalizados.</li>
        <li>Redacción de anuncios.</li>
        <li>Configuración de campañas.</li>
        <li>Monitoreo continuo.</li>
        <li>Optimización de anuncios.</li>
        <li>Realización de pruebas A/B para identificar los elementos más efectivos de las campañas y mejorar
          continuamente.</li>
        <li>Informe mensual que detalla el rendimiento de la campaña, incluyendo métricas clave como impresiones, clicks,
           tasas de conversión y costo por adquisición.</li>
        <li>Análisis de ROI (Retorno de la inversión) y sugerencias para mejorar la efectividad de futuras
          campañas.</li>
      </ul>
      <div className='btnGris'>
        <a href="https://wa.me/3206192322" target="_blank" rel="noopener noreferrer">¡Pregunta por nuestros planes!</a>
      </div>
      <div className='servicios1a1'>
        <div className="ovalPurple">
          <div className="circleGrey"></div>
        </div>
        <h2>Creación de estrategia de Marketing Digital</h2>
      </div>
      <ul>
        <li>Auditoría de presencia digital del estado actual de las plataformas digitales de la marca,
          incluyendo sitio web, redes sociales, SEO y otros canales.</li>
        <li>Análisis de la competencia directos e indirectos para identificar oportunidades y amenazas
          en el mercado.</li>
        <li>Establecimiento de metas SMART, alineados con los objetivos generales del negocio.</li>
        <li>KPIs y Métricas claves de rendimiento y las métricas que se utilizarán para medir el éxito de
          las estrategias.</li>
        <li>Desarrollo de una estrategia integral para aumentar la presencia y el compromiso en redes sociales.</li>
        <li>Gestión de contenidos con creación y calendario de parrilla de comunicación detallado que incluye fechas,
          temas, formatos y canales de distribución.</li>
        <li>Estrategia de Publicidad Digital (PPC): Campañas de Google Ads, creación y gestión de campañas de pago por
          click, incluyendo búsqueda, display, shopping y video.</li>
        <li>Publicidad en Redes Sociales: Diseño y ejecución de campañas publicitarias en plataformas como Facebook,
          Instagram, LinkedIn, Twitter y más.</li>
        <li>Análisis y reportes de rendimiento de las estrategias y campañas, con insights y recomendaciones estratégicas.</li>
        <li>Uso de análisis de datos para ajustar y optimizar las estrategias de marketing digital.</li>
    
      </ul>
      <div className='btnGris'>
        <a href="https://wa.me/3206192322" target="_blank" rel="noopener noreferrer">¡Pregunta por nuestros planes!</a>
      </div>
      
      <div className='servicios1a1'>
        <div className="ovalPurple">
          <div className="circleGrey"></div>
        </div>
        <h2>Asesorías en Marketing Digital</h2>
      </div>
      <ul>
        <li>Desarrollo de estrategias de marketing digital.</li>
        <li>Ayuda en la identificación de objetivos claros y medibles para las campañas de marketing 
          digital.</li>
        <li>Creación de planes estratégicos y detallados que se alineen con los objetivos de negocio
          y las necesidades del mercado.</li>
        <li>Segmentación de audiencias para mejorar la efectividad de la comunicación y campañas.</li>
        <li>Desarrollo de estrategias para aumentar la presencia y el compromiso en plataformas sociales.</li>
        <li>Asesoría en la creación, programación y gestión de contenidos para redes sociales.</li>
        <li>Asesoramiento en la configuración y optimización de campañas publicitarias en redes sociales.</li>
        <li>Desarrollo de estrategias de remarketing para reenganchar a usuarios que han interactuado previamente
          con la marca.</li>
        <li>Interpretación de datos de los resultados para los informes de las campañas, con recomendaciones
          estratégicas basadas en éstos.</li>
      </ul>
      <div className='btnGris'>
        <a href="https://wa.me/3206192322" target="_blank" rel="noopener noreferrer">¡Pregunta por nuestros planes!</a>
      </div>
      <div className='servicios1a1'>
        <div className="ovalPurple">
          <div className="circleGrey"></div>
        </div>
        <h2>Otros serivicios</h2>
      </div>
      <h2>Diseño de:</h2>
      <ul>
        <li>Tarjetas de presentación.</li>
        <li>Brochure.</li>
        <li>Flyer.</li>
        <li>Menú.</li>
        <li>Portafolio de servicios.</li>
        <li>Diseño y desarrollo de landing pages (Página WEB) con dominio personalizado.</li>
      </ul>
      <h2>Si no encuentras el servicio que <br></br>necesitas o tienes preguntas</h2>
      <div className='btnGris'>
        <a href="https://wa.me/3206192322" target="_blank" rel="noopener noreferrer">¡Hablemos aquí!</a>
      </div>
      <p className='mensajeSecundario'>Dale click en el botón y hablemos por WhatsApp</p>
      <WhatsAppIcon />
    </div>
  );
};

export default servicios;
