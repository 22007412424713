import React from 'react';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import ReactDOM from 'react-dom/client';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HelmetProvider> {/* Envuelve tu aplicación con HelmetProvider */}
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </HelmetProvider>
);


