import React from 'react';
import { NavLink } from 'react-router-dom'; // Usamos NavLink para manejar la clase activa

import '../App.css';

const footbar = () => {
  return (
    <nav className="footbar">
      <div className="menu">
        <ul>
          <li>
            <NavLink to="/" className={({ isActive }) => (isActive ? 'active' : '')}>
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/equipo" className={({ isActive }) => (isActive ? 'active' : '')}>
              Equipo
            </NavLink>
          </li>
          <li>
            <NavLink to="/servicios" className={({ isActive }) => (isActive ? 'active' : '')}>
              Servicios
            </NavLink>
          </li>
        </ul>  
        
      </div>
      <div className="info">
          <a href="mailto:sandiacreativemarketing@gmail.com">sandiacreativemarketing@gmail.com</a>
          <a href="https://wa.me/3206192322" target="_blank" rel="noopener noreferrer">320 619 2322 | Habla con Naty</a>
          <a href="https://wa.me/3045957412" target="_blank" rel="noopener noreferrer">304 595 7412 | Habla con Cata</a>
        </div>
      
      <div className="redes">
        <a href="https://www.instagram.com/sandiacreativemarketing?igsh=cTV1cDYxM2x4czBv" target="_blank" rel="noopener noreferrer">
          <img src="LogoInstagram.png" alt="Instagram" />
        </a>
        <a href="https://www.ejemplo.com" target="_blank" rel="noopener noreferrer">
          <img src="LogoTikTok.png" alt="TikTok" />
        </a>
      </div>
  </nav>
  );
};

export default footbar;
