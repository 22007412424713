import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import Servicios from './pages/servicios';
import Equipo from './pages/equipo';
import Navbar from './components/navbar';
import Footbar from './components/footbar';

function App() {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/equipo" element={<Equipo />} />
        <Route path="/servicios" element={<Servicios />} />
        
      </Routes>
      <Footbar />
    </div>
  );
}

export default App;
