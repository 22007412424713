import React from 'react';
import WhatsAppIcon from '../components/whatsappIcon';
import '../App.css';
import { Helmet } from 'react-helmet';

const home = () => {
  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Organization", // O "LocalBusiness" si tienes ubicación física
    "name": "Sandía Creative Marketing",
    "url": "https://www.sandiacreativemarketing.com",
    "logo": "https://sandiamercadeo.com/logoSC.png",
    "description": "Somos una agencia especializada en estrategias de marketing digital, gestión de redes sociales, publicidad digital, SEO y SEM, y diseño de landing pages.",
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+57 320 619 2322",
      "contactType": "CEO",
      "areaServed": "Colombia",
      "availableLanguage": "Spanish"
    },
    "sameAs": [
      "https://www.instagram.com/sandiacreativemarketing?igsh=cTV1cDYxM2x4czBv"
    ],
    "hasOfferCatalog": {
      "@type": "OfferCatalog",
      "name": "Servicios de Marketing",
      "itemListElement": [
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "serviceType": "Estrategias de Marketing Digital",
            "description": "Creamos estrategias personalizadas para hacer crecer tu negocio en línea."
          }
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "serviceType": "Gestión de Redes Sociales",
            "description": "Administramos tus redes sociales para maximizar el alcance y engagement de tu marca."
          }
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "serviceType": "Publicidad Digital",
            "description": "Campañas publicitarias en Google Ads, Facebook Ads, y otras plataformas."
          }
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "serviceType": "SEO y SEM",
            "description": "Mejora tu posicionamiento en los motores de búsqueda y aumenta tu visibilidad."
          }
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "serviceType": "Diseño y Desarrollo de Landing Pages",
            "description": "Diseñamos y desarrollamos landing pages efectivas con dominio propio."
          }
        }
      ]
    }
  };
  
  return (
    <div className='home'>
       <Helmet>
          <title>Sandía Creative Marketing</title>
          <meta name="description" content="Agencia de marketing digital especializada en SEO, SEM, y gestión de redes sociales." />
          <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
      </Helmet>
      <div className='hello'>
        <div className='bienvenidos'>
          <h1 >¡Hello!</h1>
          <h2 style={{ fontWeight: 'normal', margin:"0",padding:"0"}}>Bienvenidos a</h2>
          <h2 style={{ margin:"0",padding:"0",fontWeight: 'bold' }}>Sandía Creative</h2>
        </div>
        <div className='separadorMorado'><p></p></div>
        <div className='mensajeHello'>
          <p><b>¡El lugar donde entendemos que cada
          negocio es único!</b> Es por esto que
          personalizamos nuestras estrategias 
          para que se adapten perfectamente a
          tus necesidades y objetivos.</p>
        </div>
      </div>
      <div className='solucion'>
        <p>¿Buscas aumentar tu presencia en redes sociales, optimizar tu
        sitio web para motores de búsqueda o lanzar una campaña
        publicitaria efectiva? <b>Pues estamos aquí para ayudarte.</b></p>

      </div>
      <div className='mensajeMoradoOvalo'>
        <p>¡Transforma tu marca con nosotras!</p>
      </div>
      <div className='como'>
        <h3>¿Cómo lo hacemos?</h3>
      </div>
      <div className='serviciosHome'>
        <div className='servicios1'>
          <div className='servicios1a'>
            <div className='servicios1a1'>
              <div className="ovalPurple">
                <div className="circleGrey"></div>
              </div>
              <h2 style={{ fontWeight: 'normal' }} >Estrategias de Marketing Digital</h2>
            </div>
            <div className='servicios1a2'>
              <div className="ovalPurple">
                <div className="circleGrey"></div>
              </div>
              <h2 style={{ fontWeight: 'normal' }} >Gestión de Redes Sociales</h2>
            </div>
            
          </div>
          <div className='servicios1b'>
            <div className='servicios1b1'>
              <div className="ovalGrey">
                <div className="circlePurple"></div>
              </div>
              <h2 style={{ fontWeight: 'normal' }}>Publicidad Digital</h2>
            </div>
            <div className='servicios1b2'>
              <div className="ovalGrey">
                <div className="circlePurple"></div>
              </div>
              <h2 style={{ fontWeight: 'normal' }}>SEO y SEM</h2>
            </div>  
          </div>
        </div>
        <div className='servicios2'>
          <div className="ovalGrey">
            <div className="circlePurple"></div>
          </div>
          <h2 style={{ fontWeight: 'normal' }} >Diseño y desarrollo de landing pages con dominio propio</h2>
        </div>
        
      </div>
      <div className='mensajeMorado'>
        <h2 >¿Por qué elegirnos como tu equipo?</h2>
      </div>
      <div className='elegirnos'>
        <div className='fotoTodas'>
          <img src="fotoEquipo.png" alt="LogoTodas" />
        </div>
        <div className='mensajeEleccion'>
          <div className='textoEleccion'>
            <p>Nos tomamos el tiempo para conocer tu negocio y convertirnos en una extensión de tu equipo.</p>
            <p>Nuestro equipo está lleno de ideas frescas y estrategias innovadoras.</p>
            <p>Trabajamos con datos y métricas para asegurarnos de que cada acción tenga un impacto positivo.</p>
          </div>
          <div className='mensajeContacto'>
            <div className='btnGris'>
              <a href="https://wa.me/3206192322" target="_blank" rel="noopener noreferrer">¡Hablemos de tu proyecto!</a>
            </div>
            <p className='mensajeSecundario'>Dale click en el botón y hablemos por WhatsApp</p>
          </div>
          
        </div>
      </div>
      
    
      <WhatsAppIcon />
    </div>
  );
};

export default home;
