import React from 'react';
import WhatsAppIcon from '../components/whatsappIcon';

const acerca = () => {
  return (
    <div className='equipo'>
      <div className='titulo'>
        <h2>Creemos en todo lo
        grande que sucede
        cuando la creatividad
        se une con la estrategia</h2>
      </div>
      <div className='introduccion'>
        <p>Nos apasiona ayudarte a conectar con tu audiencia de una manera
        auténtica y efectiva. Con más de 7 años de experiencia, estamos aquí
        para construir una relación contigo, basada en la confianza, la
        transparencia y resultados medibles.</p>
      </div>
      <div className='conocenos'>
        <p>¡Conócenos!</p>
      </div>
      
      <div className='conocenosCadaUna'>
        <div className='naty'>
          <img src="Naty.png" alt="fotoNaty" />
          <h2>
            <span className="nombre-negro">Naty </span> 
            <span className="nombre-morado">Rendón</span>
          </h2>
          <p>Co founder</p>
          <div className="natyDescripcion">
          <p>
            Estratega digital, diseñadora gráfica y una
            apasionada por estrategias que conecten. Con una
            mezcla de análisis, muchísima creatividad y
            una comunicación asertiva, desarrollo experiencias
            visuales que ayudan a las marcas a conectar con su
            público.
          </p>

          </div>
          
        </div>
        <div className='cata'>
          <img src="Cata.png" alt="fotoCata" />
          <h2>
            <span className="nombre-negro">Cata </span> 
            <span className="nombre-morado">Santamaria</span>
          </h2>
          <p>Co founder</p>
          <div className='cataDescripcion'>
            <p>Project Manager, apasioanda por la creación de
            contenido y el marketing digital. Mi enfoque integral
            me permite convinar mi experiencia en gestion de
            proyectos y conocimientos en marketing para crear
            estrategias efectivas que potencien tu marca.</p>
          </div>
        </div>    
      </div>
      <div className='mensajeContactoEquipo'>
          <p>Cada acción estará pensada para ayduarte a
          conectar con tu audiencia y alcanzar tus objetivos.</p>
          <div className='btnGris'>
            <a href="https://wa.me/3206192322" target="_blank" rel="noopener noreferrer">¡Hagamos equipo!</a>
          </div>
          <p className='mensajeSecundario' style={{ color:'#515151' }}>Dale click en el botón y hablemos por WhatsApp</p>

      </div>
      
      <WhatsAppIcon />
    </div>
  );
};

export default acerca;
